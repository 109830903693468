import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Services`}</h2>
    <ProjectCard title="Website Building and Hosting" link="./web-development" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Make sure your web presence reflects your values. From domain registration to full custom websites, we can handle the tech so you can run your business. 
    </ProjectCard>
    <ProjectCard title="Backup and Disaster Recovery" link="./backup" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Is your business ready to recover from Ransomware? You will have to deal with it. We can keep your data safe with comprehensive backups and security monitoring. </ProjectCard>
    <ProjectCard title="Tech Support" link="./tech-support" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  We know Windows PCs, Macs, smart refrigerators and everything in between. We offer both on-demand support and monthly phone support contracts. We go beyond fixing your problem\: we help you manage tech so that problems get fixed before you even know something is wrong.
    </ProjectCard>
    <ProjectCard title="Business Software" link="./software" bg="linear-gradient(to right, #75458F 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Email, expense tracking, contact management - we can put together a software package that's in your budget and meets your needs.
    </ProjectCard>
    <ProjectCard title="Media and Livestream Services" link="./media" bg="linear-gradient(to right, #ED1E79 0%, #662D8C 100%)" mdxType="ProjectCard">
  Want to broadcast your event to YouTube, Facebook, Twitch, or all of them at the same time? Want to start a podcast? We can get you on the right track with the right tools and training for your budget.
    </ProjectCard>
    <ProjectCard title="Phones Systems" link="./phones" bg="linear-gradient(to right, #FBB03B 0%, #D4145A 100%)" mdxType="ProjectCard">
  Leverage the power of VoiP to make and recieve calls how and where you need without breaking the bank. 
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      