import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`For a free consultation, send an email to `}<a parentName="p" {...{
        "href": "mailto:info@druidic.dev"
      }}>{`info@druidic.dev`}</a>{` or call/text (502) 200-1166. You can expect a resonse within 24 hours.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      